import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  label?: string
  title?: string
}

export const Accordions = memo(function Accordions({
  items,
  label,
  title,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <IconLogo />

      {label ? <Label>{label}</Label> : null}
      {title ? (
        <Title>
          {title}
          <Line />
        </Title>
      ) : null}

      <AccordionsWrap allowZeroExpanded>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </AccordionsWrap>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.25rem 0 8.125rem;
  padding: 0 10.556vw;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    margin: 8.125rem 0 7.5rem;
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 2.25rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  > div {
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const AccordionsWrap = styled(Accordion)`
  max-width: 59.375rem;
  margin: 6.5rem auto 0;
  text-align: left;

  > div {
    &.expanded {
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      margin-top: 0.25rem;
      transition: background 0.4s ease-in-out;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 5rem;
  }

  @media (max-width: 767px) {
    margin-top: 3.75rem;
  }
`
